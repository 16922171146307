<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <div class="onboarding-content-wrap">
            <div class="markers">
                <div class="stateStatusCircles">
                    <div v-for="i in 5">
                        <div v-if="i !== 3 || showPendingStep"
                             :class="{ active: stepIndex === i - 1, prev: stepIndex > i - 1 }"
                             class="stateCircle"
                        ></div>
                    </div>

                </div>
            </div>
            <div v-if="comment" class="pending-comment-wrap">
                <h2>Комментарий от разработчика:</h2>
                <p class="pending-comment">{{comment}}</p>
            </div>
            <component v-if="stepIndex != null"
                       :is="steps[stepIndex].component"
                       :crmConnectorType="crmConnectorType"
                       @refreshStep="refreshStep"
            ></component>
        </div>
    </div>
</template>

<script>
import { computed, getCurrentInstance, ref, onMounted, onBeforeMount } from 'vue'
import useRouter from '@/hooks/useRouter'
import { useProject } from '@root/src/hooks/useProject'
import { useAdminStore } from '../../../store/stores/admin'
import api from '../../../api/api'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import  SelectCRMSystemView from '@/components/project/onBoarding/SelectCRMSystemView.vue'
import  AuthorizationInCRMSystemView from '@/components/project/onBoarding/AuthorizationInCRMSystemView.vue'
import  AuthorizationPending from '@/components/project/onBoarding/AuthorizationPending.vue'
import  BotSettingsView from '@/components/project/onBoarding/BotSettingsView.vue'
import  ConnectorSettings from '@/components/project/onBoarding/ConnectorSettings.vue'
import  Billing from '@/modules/marketplace/views/Billing.vue'
import { getCredential } from '../../../api/admin'
import store from '../../../store'
import { PENDING_CONNECTOR_TYPES } from '../../../vars/general'

export default {
    name: 'OnBoarding',
    components: {
        SelectCRMSystemView,
        AuthorizationInCRMSystemView,
        AuthorizationPending,
        ConnectorSettings,
        BotSettingsView,
        Breadcrumbs
    },
    props: {

    },

    setup(props) {
        const router = useRouter()
        const root = getCurrentInstance().proxy
        const { project } = useProject()
        const breadcrumbs = [
            {
                text: 'ВАШИ ПРОЕКТЫ',
                to: '/projects',
            },
            {
                text: 'Подключение нового проекта',
                disabled: true,
                to: '',
            },
        ]
        const steps = ref([
            {component: SelectCRMSystemView},
            {component: AuthorizationInCRMSystemView},
            {component: AuthorizationPending},
            {component: ConnectorSettings},
            {component: BotSettingsView},
            {component: Billing},
        ])
        const showPendingStep = ref(true)
        const comment = ref('')
        const crmConnectorType = ref('')
        const getCurrentStep = async () => {
            const allConnectors = project.value.connectors
            const haveConnector = allConnectors.length

            const selectedCrm = window.localStorage.getItem(project.value.id + '-onboardingSelectedCrm')
            if (!haveConnector) {
                if (selectedCrm) {
                    const path = router.router.currentRoute.path.split('/').slice(0, 3).join('/')
                    return 1
                }
                return 0
            }
            const pendingConnectors = await api.project.getPendingConnectorsByProject({ projectId: project.value.id })
            const pendingConnector = pendingConnectors.data[0]
            if (pendingConnector) {
                comment.value = await getCredential({ connectorId: pendingConnector.connectorId, name: 'pendingComment' }).then((res) => res.data)
                if (comment.value) {
                    store.commit('project/updateCurrentAddingConnector', pendingConnector.connectorType)
                    localStorage.setItem(project.value.id + '-onboardingSelectedCrm', pendingConnector)
                    return 1
                }
                return 2
            }

            const crmConnector = project.value.connectors.find(
              (connector) => connector.connectorType !== 'whatsappBasis' && connector.connectorType !== 'whatsapp'
            )

            crmConnectorType.value = crmConnector?.connectorType
            if (crmConnector?.isDraft) {
                return 3
            }

            const basisConnector = project.value.connectors.find(
                (connector) => connector.connectorType === 'whatsappBasis' || connector.connectorType === 'whatsapp'
            )

            const isNeedToGoOnBot =
                (!basisConnector || basisConnector?.isDraft || !basisConnector?.alive ) &&
                project.value.connectors?.filter((a) => a.connectorId).length > 0

            if (isNeedToGoOnBot) {
                return 4
            }

            router.router.push(`/project/${project.value.id}`)
        }
        const stepIndex = ref()
        const isShowPendingStep = () => {
            const selectedCrm = JSON.parse(window.localStorage.getItem(project.value.id + '-onboardingSelectedCrm'))
            if (!selectedCrm) {
                return true
            } else {
                return PENDING_CONNECTOR_TYPES.includes(selectedCrm.type)
            }
        }
        const stepBack = () => {
            this.stepIndex--
        }
        const refreshStep = async () => {
            stepIndex.value = await getCurrentStep()
            showPendingStep.value = isShowPendingStep()
        }
        onBeforeMount(async () => {
            const isOnBoardingActive = computed(() => project.value?.isOnBoardingActive)
                if (!isOnBoardingActive.value) {
                    router.router.push(`/project/${project.value.id}`)
                    return
                }
            await refreshStep()
          }
        )

        return {
            breadcrumbs,
            steps,
            stepIndex,
            stepBack,
            refreshStep,
            getCurrentStep,
            comment,
            showPendingStep,
            crmConnectorType
        }
    },
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
.onboarding-content-wrap
    margin-top: 80px
    ::v-deep .content-wrapper
        margin-top: 0
    ::v-deep .content > div
        margin-top: 0 !important
.markers
    width: 100%
    .stateCircle
        width: 24px
        height: 24px
        border-radius: 50%
        background-color: $light-gray-4
        margin: 5px
    .active
        border: 2px solid $main-light-2
    .prev
        background-color: $main-light-2
    .stateStatusCircles
        display: flex
        flex-direction: row
        width: 100%
        align-items: center
        justify-content: center
        margin-top: 30px
::v-deep .titleBox
    width: 100%
    font-size: 34px
    text-align: left
.pending-comment-wrap
    width: 1000px
    margin: auto
    color: red
    .pending-comment
        width: 100%
        font-size: 16px
        padding: 20px
        border: 1px solid red
</style>