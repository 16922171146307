<template>
    <div class="auth">
        <h2>Для интеграции с нами выполните, пожалуйста, <a
          href="http://help.dataheroes.pro/?slug=840-yunivers"
          target="_blank"
        >инструкцию</a
        > и по итогу заполните поля ниже</h2>
        <div class="form-block pa-10">
            <v-form v-model="valid">
                <v-row class="ma-0">
                    <TextField
                        type="password"
                        color="main"
                        filled
                        v-model="url"
                        label="URL"
                        required
                    ></TextField>
                </v-row>
                <v-row class="ma-0">
                    <TextField
                        type="password"
                        color="main"
                        filled
                        v-model="basicAuthU"
                        label="BasicAuth — u"
                        required
                    ></TextField>
                </v-row>
                <v-row class="ma-0">
                    <TextField
                        type="password"
                        color="main"
                        filled
                        v-model="basicAuthP"
                        label="BasicAuth — p"
                        required
                    ></TextField>
                </v-row>
                <v-row class="ma-0">
                    <TextField
                        type="password"
                        color="main"
                        filled
                        v-model="apiKey"
                        label="ApiKey"
                        required
                    ></TextField>
                </v-row>
                <v-row class="ma-0">
                    <TextField
                        type="password"
                        color="main"
                        filled
                        v-model="apiPass"
                        label="ApiPass"
                        required
                    ></TextField>
                </v-row>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <div class="btn-wrap">
                    <v-btn
                      color="white"
                      class="black--text to-crm-select-btn"
                      @click="$parent.$emit('goBackToCrm')"
                    >Назад к выбору CRM</v-btn>
                    <v-btn
                      color="main"
                      class="white--text"
                      @click="login"
                      :loading="loading"
                      :disabled="btnDisabled"
                    >Войти</v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import cfg from '../../../../config'
import { CONNECTOR_TYPES } from '@/vars/general'
import { login } from '@root/src/api/connectors/universeSoft'
export default {
    name: 'YcAuth',
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        url: '',
        basicAuthU: '',
        basicAuthP: '',
        apiKey: '',
        apiPass: '',
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),

        btnDisabled() {
            if (
                !this.url ||
                !this.basicAuthU ||
                !this.basicAuthP ||
                !this.apiKey ||
                !this.apiPass ||
                !this.valid
            ) {
                return true
            }
            return false
        },
    },
    methods: {
        async login() {
            const credentials = {
                url: this.url,
                user: this.basicAuthU,
                password: this.basicAuthP,
                apiKey: this.apiKey,
                apiPass: this.apiPass,
            }
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.universeSoft,
            })
            const isLogin = await login(connector, credentials)
            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 12px
    max-width: 1200px
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
.btn-wrap
    margin-top: 12px
    display: flex
    gap: 20px
</style>
